  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Roboto:wght@100;300;400;700&display=swap');
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-size: 62.5%;
    font-family:  'Poppins';
}

.ownerinfo {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 150px;
}



.ownerinfo a {
  color: #4f772d; 
  font-size: 2rem;
  text-decoration: none;
  
}
.owner-pic {
  width: 50px;
  height: auto;
  display: inline;
  transition: all ease;
}
.owner-pic:hover {
  transition: all 2s ease;
  transform: scale(1.4);
}